import { Grid as MuiGrid } from '@mui/material'
import { styled } from '@mui/system'
import Menu from '../../molecules/menu/menu'
import { Link } from '../../molecules/menu/menu-first-level.styled'
import { ChangeCountryLanguage } from '../change-country-language'

const shouldForwardProp = (prop) => !['message', 'position'].includes(prop)

const Header = styled('header', { shouldForwardProp })``

const GridContainer = styled(MuiGrid)`
	padding: ${({ theme }) => theme.spacing(0, 3)};
	position: relative;
	z-index: 1;
	.mini-trigger {
		padding: 0;
	}
	.MuiSvgIcon-root {
		font-size: 16px !important;
	}
`

const LogoWrapper = styled('div')`
	max-width: 158px;
	width: 100%;
`

const MenuStyled = styled(Menu)`
	display: block;
	padding: 0;

	${({ theme }) => theme.breakpoints.up('md')} {
		padding: 0;
	}

	${Link} {
		padding: 0;
	}
`

const ChangeCountryLanguageStyled = styled(ChangeCountryLanguage)`
	.MuiTypography-root {
		font-size: ${({ theme }) => theme.typography.pxToRem(12)};
	}
`

export { GridContainer, Header, LogoWrapper, MenuStyled, ChangeCountryLanguageStyled }
